import { useRef, useState, useEffect } from 'react';
import Head from 'next/head';
import styles from '../styles/Splash.module.css';

import { Fireworks } from 'fireworks-js';

export default function Splash({ gsap, initial, gallery, land, player0, player1, player2, paymentDone, closeCheckout, cursorClickEvent }) {

  const [loading, setLoading] = useState(0);

  const tl = useRef();

  useEffect(() => {

    tl.current = gsap.timeline();

  }, []);

  useEffect(() => {

    if (paymentDone) {

      gsap.to(['#loading', '#fireworks'], 0.5, {
        opacity: 1,
        pointerEvents: 'auto',
        onComplete: () => closeCheckout()
      });

      const container = document.querySelector('#fireworks');
      const fireworks = new Fireworks(container);

      fireworks.start();

    }

  }, [paymentDone]);

  useEffect(() => {

		if (initial) {

			var loadingValue = (gallery ? 20 : 0) + (land ? 20 : 0) + (player0 ? 20 : 0) + (player1 ? 20 : 0) + (player2 ? 20 : 0);

      setLoading(loadingValue);

		}

	}, [initial, gallery, land, player0, player1, player2]);

  useEffect(() => {

    if (loading === 0) {

      gsap.set('#cursor', {
        xPercent: -50,
        yPercent: -50,
        scale: 100
      });

    } else if (loading === 20) {

      var Cont = { val: 0 };

      tl.current.to(Cont, 0.5, { val: 20, roundProps: "val", ease: "none", onUpdate: () => {

        document.querySelector("#loadingCounter span").innerHTML = Cont.val;

      } });

      tl.current.to('#layer', 0.5, {
        delay: -.5,
        backgroundColor: 'rgba(255, 255, 255, 0.68)',
        backdropFilter: 'blur(80px)'
      });

		} else if (loading === 40) {

      var Cont = { val: 20 };

      tl.current.to(Cont, 0.5, { val: 40, roundProps: "val", ease: "none", onUpdate: () => {

        document.querySelector("#loadingCounter span").innerHTML = Cont.val;

      } });

      tl.current.to('#layer', 0.5, {
        delay: -.5,
        backgroundColor: 'rgba(255, 255, 255, 0.58)',
        backdropFilter: 'blur(60px)'
      });

		} else if (loading === 60) {

      var Cont = { val: 40 };

      tl.current.to(Cont, 0.5, { val: 60, roundProps: "val", ease: "none", onUpdate: () => {

        document.querySelector("#loadingCounter span").innerHTML = Cont.val;

      } });

      tl.current.to('#layer', 0.5, {
        delay: -.5,
        backgroundColor: 'rgba(255, 255, 255, 0.48)',
        backdropFilter: 'blur(40px)'
      });

		} else if (loading === 80) {

      var Cont = { val: 60 };

      tl.current.to(Cont, 0.5, { val: 80, roundProps: "val", ease: "none", onUpdate: () => {

        document.querySelector("#loadingCounter span").innerHTML = Cont.val;

      } });

      tl.current.to('#layer', 0.5, {
        delay: -.5,
        backgroundColor: 'rgba(255, 255, 255, 0.38)',
        backdropFilter: 'blur(20px)'
      });

		} else if (loading === 100) {

      var Cont = { val: 80 };

      tl.current.set('#loading', { pointerEvents: 'auto' });

      tl.current.to(Cont, 0.5, { val: 100, roundProps: "val", ease: "none", onUpdate: () => {

        document.querySelector("#loadingCounter span").innerHTML = Cont.val;

      }, onComplete: () => {

        gsap.to("#loadingCounter", 1, { scale: 0.5, opacity: 0 });

        gsap.to('#layer', 0.5, {
          backgroundColor: 'rgba(255, 255, 255, 0.18)',
          backdropFilter: 'blur(10px)'
        })

        gsap.set('#cursor', { delay: 1.25, backdropFilter: 'blur(40px)' });

        gsap.timeline()
          .to('#cursor', 1, { scale: 1 })
          .set("#cursor", { delay: -.1, zIndex: 999 })
          .to('#cursorLoad', 0.5, { opacity: 0, onComplete: () => {
            gsap.to('#cursor', 0.5, { backgroundColor: 'rgba(0, 0, 0, 0.28)' });
            gsap.to('#cursorClick span', 0.25, { opacity: '1', onComplete: () => {
              gsap.to('canvas', 0.5, { pointerEvents: 'auto' });
            } });
          } });


    		window.addEventListener('mousemove', (e) => {

    			gsap.set('#cursor', { top: e.clientY, left: e.clientX });

    		});

      } });

      tl.current.to('#layer', 0.5, {
        delay: -.5,
        backgroundColor: 'rgba(255, 255, 255, 0.28)',
        backdropFilter: 'blur(10px)'
      });

      gsap.to('#cursorLoad', 0.5, { opacity: 0.18 });

    }

  }, [loading]);

  const hideSplash = () => {

		gsap.timeline()
			.to('#loadingCounter', 0.15, { opacity: 0 })
	  	.to(['#loading', '#fireworks'], 1, { opacity: 0, pointerEvents: 'none' });

		cursorClickEvent(false);

  }

  return (
    <div className={ styles.splash }>

			<div id="loadingCounter" className={ styles.splashRow }><span>0</span>%</div>
      <div id="layer" className={ styles.layer }></div>

      <div id="fireworks" className={ styles.fireworks } onClick={ () => hideSplash() }>
        <div>

          <div className={ styles.fireworksTitle }>
            HURRAY!
          </div>

          <div className={ styles.fireworksDesc }>
            Your order is being processed.
          </div>

        </div>
      </div>

    </div>
	)
}
