import { useRef, useState, useEffect } from 'react';
import Head from 'next/head';
import Link from 'next/link';
import { useRouter } from 'next/router';
import styles from '../styles/Home.module.css';

import Splash from '../components/splash.js';
import Cart from '../components/cart.js';

import gsap from 'gsap';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import * as THREE from "three";
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';

import Nebula, { SpriteRenderer } from "three-nebula";
import json from '../public/json/smoke.json';

const dracoLoader = new DRACOLoader();
dracoLoader.setDecoderPath('https://www.gstatic.com/draco/versioned/decoders/1.4.3/');
dracoLoader.setDecoderConfig({ type: 'js' });

export default function Home() {

	const [initial, setInitial] = useState(false);
	const [start, setStart] = useState(false);

	const [cameraMove, setCameraMove] = useState(false);
	const [cameraGlobal, setCameraGlobal] = useState(null);
	const [controlsGlobal, setControlsGlobal] = useState(null);

	const [size, setSize] = useState([]);

	const [infos, setInfos] =useState(false);
	const [audio, setAudio] = useState(false);

	const [player, setPlayer] = useState(0);
	const [cameraType, setCameraType] = useState(0);

	const [gallery, setGallery] = useState(null);
	const [land, setLand] = useState(null);
	const [planeBlack, setPlaneBlack] = useState(null);

	const [player0, setPlayer0] = useState(null);
	const [player1, setPlayer1] = useState(null);
	const [player2, setPlayer2] = useState(null);

	const [videoScreen, setVideoScreen] = useState(null);

	const [disableButtonPlayer, setDisableButtonPlayer] = useState(null);

  const [clientSecret, setClientSecret] = useState('');
  const [paymentIntent, setPaymentIntent] = useState('');

	const [cartToggle, setCartToggle] = useState(false);
	const [checkout, setCheckout] = useState(0);
  const [paymentDone, setPaymentDone] = useState(false);

	const [cursorClickText, setCursorClickText] = useState('CLICK');

	const router = useRouter();

	const stripe = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY, { locale: 'en' });

  const appearance = {
	  theme: 'none',
	  rules: {
			'.Input': {
				fontFamily: 'SuisseIntl',
				fontSize: '14px',
				backgroundColor: 'rgba(0, 0, 0, 0.28)',
				color: "#FFFFFF",
				outline: 'none',
				borderWidth: '0px',
				cursor: 'none'
			},
			'.Input:hover': {
				backgroundColor: 'rgba(255, 255, 255, 0.28)',
				cursor: 'none'
			},
			'.Input:focus': {
				backgroundColor: 'rgba(255, 255, 255, 0.28)',
				outline: 'none',
				borderWidth: '0px'
			},
	    '.Input--invalid': {
				backgroundColor: 'rgba(255, 0, 0, 0.28)',
				color: '#FFF'
	    },
			'.Error': {
				color: 'rgba(255, 0, 0, 0.28)',
				fontFamily: 'SuisseIntl',
			},
			'.Label': {
				fontFamily: 'SuisseIntl',
				color: '#FFFFFF',
				cursor: 'none'
			}
	  }
	};

	const fonts = [{
		family: 'SuisseIntl',
		src: 'url(https://test.hellodude.it/font/SuisseIntl.woff)'
	}]

  const options = { clientSecret, appearance, fonts };

	let container, scene, renderer, camera, cameraControls, clock, mixer, mixer2;

	const timeOut = useRef();
	const mouseBlock = useRef();
	const mouseTimeline = useRef();

	const restartInactivity = () => {

		if (window.innerWidth > 768) {

			timeOut.current = setTimeout(() => {

				cursorClickEvent(false);

				gsap.to('#buy', 0.5, { y: '70px', ease: "ease" });
				gsap.to(['#player', '#camera', '#audio'], 0.5, { y: '-70px', ease: "ease" });


			}, 5000);

		}

	}

  useEffect(() => {

    fetch('api/stripe_intent', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ amount: 3500, payment_intent_id: '' }),
    }).then((res) => res.json()).then((data) => {

      setClientSecret(data.client_secret), setPaymentIntent(data.id);

    });

		mouseTimeline.current = gsap.timeline();

		setInitial(true);

		gsap.defaults({ ease: "ease", duration: 1 });

		window.addEventListener('resize', appHeight);
		appHeight();

  }, []);

  useEffect(() => {

		if (initial) { init(); }

    var FontFaceObserver = require('fontfaceobserver');
    var fontA = new FontFaceObserver('Annonce');

    fontA.load().then(async() => gsap.set('body', { opacity: 1 }) );

  }, [initial]);

  useEffect(() => {

		if (start) {

			cursorClickEvent(false);

			const audio = document.getElementById('audioEl');
			audio.src = "../audio/1.mp3";

			const audioClick = document.getElementById('audioClick');
			audioClick.volume = 0.48;
			audioClick.src = "../audio/click.mp3";

			const video = document.getElementById('video');
			video.src = "../video/1.mp4";

			video.play();

			setTimeout(() => {

				videoScreen.visible = true;

				toggleAudio();

				gsap.timeline()
					.to('#buy', 0.5, { delay: 0, y: '0%', ease: "ease" })
					.to('#player', 0.5, { delay: -0.20, y: '0%', ease: "ease" })
					.to('#camera', 0.5, { delay: -0.20, y: '0%', ease: "ease" })
					.to('#audio', 0.5, { delay: -0.20, y: '0%', ease: "ease", onComplete: () => {

						gsap.set('#logo', { zIndex: 1 });

						setCursorClickText('DRAG');
						cursorClickEvent(true, false);

					} });

			}, 3500);

			gsap.timeline()
				.to('#loadingCounter', 0.25, { opacity: 0 })
		  	.to('#loading', 1, { opacity: 0, pointerEvents: 'none' });

			gsap.to(cameraGlobal.position, 5, { x: 0, y: 6.37, z: -39.27, onComplete: () => {

				restartInactivity();

				window.addEventListener('mousemove', (e) => {

					clearTimeout(timeOut.current);

					if (!mouseBlock.current) {

						gsap.to('#buy', 0.5, { y: '0%', ease: "ease" });
						gsap.to(['#player', '#camera', '#audio'], 0.5, { y: '0%', ease: "ease" });

						restartInactivity();

					}

				});

				window.addEventListener('resize', () => {

					if (timeOut.current) { clearTimeout(timeOut.current); }

					restartInactivity();

				});

			} });

		}

  }, [start]);

	useEffect(() => {

		if (initial && player0 && player1 && player2) {

			setDisableButtonPlayer(true);

			audioFadeOut('audioEl');

  		gsap.to(cameraGlobal.position, 1, {
  			x: -39.29304850092914, y: 5.184403262898761, z: 0.17926221102687742,
				onComplete: () => {

					gsap.to(planeBlack.material, 0.25, { opacity: 0.985, onComplete: () => {

						if (player === 0) {

							player0.visible = true;

							player1.visible = false;
							player2.visible = false;

						} else if (player === 1) {

							player1.visible = true;

							player0.visible = false;
							player2.visible = false;

						} else {

							player2.visible = true;

							player0.visible = false;
							player1.visible = false;

						}

						gsap.to(planeBlack.material, 0.25, { delay: 0.25, opacity: 0, onComplete: () => {

							audioFadeIn('audioEl', player);

							gsap.to(cameraGlobal.position, 1, { x: 0, y: 6.37, z: -39.27, onComplete: () => {

								if (window.innerWidth > 768) {

									gsap.to('#buy', 0.5, { y: '70px', ease: "ease" });
									gsap.to(['#player', '#camera', '#audio'], 0.5, { y: '-70px', ease: "ease" });

								}

								setDisableButtonPlayer(false);

							} });

						} });

					} })

				}
  		});

		}

	}, [player]);

	useEffect(() => {

		if (start) {

			setCameraMove(true);

      controlsGlobal.enabled = false;
      cameraGlobal.enabled = false;
			

			if (cameraType === 0) {

        controlsGlobal.maxAzimuthAngle = Infinity;
        controlsGlobal.minAzimuthAngle = -Infinity;

    		gsap.to(controlsGlobal.target, 1, {
    			x: 0, y: 5, z: 0,
    			onComplete: () => {
            controlsGlobal.enabled = true;
						setCameraMove(false);
          }
    		});

    		gsap.to(cameraGlobal.position, 1, { x: 0, y: 6.37, z: -39.27 });

    		gsap.to(cameraGlobal.rotation, 1, {
    			x: -2.98, y: 0.01, z: 3.13,
          onUpdate: () => cameraGlobal.enabled = true
    		});


			} else if (cameraType === 1) {

    		gsap.to(controlsGlobal.target, 1, {
    			x: 20, y: 8, z: 18.5,
    			onComplete: () => {
            controlsGlobal.enabled = true;
						setCameraMove(false);
          }
    		});

    		gsap.to(cameraGlobal.position, 1, { x: -19.72, y: 10.35, z: 18.27 });

    		gsap.to(cameraGlobal.rotation, 1, {
    			x: -1.98, y: -1.51, z: -1.98,
          onUpdate: () => {
            cameraGlobal.enabled = true
          }
    		});

        setTimeout(() => {
          controlsGlobal.maxAzimuthAngle = 300 * Math.PI / 180;
          controlsGlobal.minAzimuthAngle = 240 * Math.PI / 180;
        }, 1000);

			} else if (cameraType === 2) {

    		gsap.to(controlsGlobal.target, 1, {
    			x: 20, y: 8, z: -17.5,
    			onComplete: () => {
            controlsGlobal.enabled = true;
						setCameraMove(false);
          }
    		});

    		gsap.to(cameraGlobal.position, 1, { x: -19.72, y: 10.16, z: -18.44 });

    		gsap.to(cameraGlobal.rotation, 1, {
    			x: -1.98, y: -1.51, z: -1.98,
    			onUpdate: () => cameraGlobal.enabled = true
    		});

        setTimeout(() => {
          controlsGlobal.maxAzimuthAngle = 300 * Math.PI / 180;
          controlsGlobal.minAzimuthAngle = 240 * Math.PI / 180;
        }, 1000);

			}

		}

	}, [cameraType]);

	useEffect(() => {

		if (infos) {

			gsap.to('#infos', 0.25, { opacity: 1, pointerEvents: 'auto' });


		} else {

			gsap.to('#infos', 0.25, { opacity: 0, pointerEvents: 'none' });

		}

	}, [infos]);

	useEffect(() => {

		if (timeOut.current && mouseBlock.current) { clearTimeout(timeOut.current); }

	}, [mouseBlock.current]);

	useEffect(() => {

		if (size.length > 0) {

	    fetch('api/stripe_intent', {
	      method: 'POST',
	      headers: { 'Content-Type': 'application/json' },
	      body: JSON.stringify({ amount: 3500 * size.length, payment_intent_id: paymentIntent }),
	    }).then((res) => res.json()).then((data) => {

	      setClientSecret(data.client_secret), setPaymentIntent(data.id);

	    });

			setCartToggle(false);

			if (checkout !== 2) { toggleAudio(); }

			setCheckout(2);
			setCameraType(2);

			gsap.timeline()
				.to(['#player', '#camera', '#audio'], 0.5, { y: '-70px', ease: "ease" })
				.to('#buy', 0.5, { delay: -.5, y: '70px', ease: "ease" })
				.to(['#close', '#pay'], 0.5, { y: '0px', ease: "ease" });

			gsap.timeline()
				.to('#infosSecond1', 0.5, { height: 0 })
				.set('#infosSecond1', { display: 'none', height: 0 })
				.set('#infosSecond2', { display: 'block' })
				.to('#infosSecond2', 0.5, { delay: 0.25, height: 'auto' });

			mouseBlock.current = true;

		}

	}, [size]);

	useEffect(() => {

    if (cartToggle) {

      gsap.to('#pay', 0.25, { y: 70 });

      gsap.timeline()
        .set('#infos', { top: 90, bottom: 'unset' })
        .to('#infosSecond2', 0.5, { height: 0 })
        .set('#infosSecond2', { display: 'none', height: 0 })
        .set('#infosSecond3', { display: 'block' })
        .to('#infosSecond3', 0.5, { delay: 0.25, height: 'auto' });

    } else {

      gsap.to('#pay', 0.25, { y: 0 });

      gsap.timeline()
        .to('#infosSecond3', 0.5, { height: 0 })
        .set('#infosSecond3', { display: 'none', height: 0 })
        .set('#infosSecond2', { display: 'block' })
        .to('#infosSecond2', 0.5, { delay: 0.25, height: 'auto' })
				.set('#infos', { top: 'unset', bottom: 90 });

    }

	}, [cartToggle]);

	const closeCheckout = () => {

		setSize([]);

		setCheckout(0);
		setCameraType(0);

		toggleAudio();

		gsap.timeline()
			.to('#infosSecond', 0.25, { opacity: 0, pointerEvents: 'auto' })
			.set('#infosSecond', { display: 'none', height: 'auto' })
			.set('#infosSecond1', { height: 'auto', display: 'block' })
			.set('#infos', { top: 'unset', bottom: 90 })
			.set(['#infosSecond2', '#infosSecond3'], { height: '0', display: 'none' });

		gsap.timeline()
			.to('#close', 0.5, { y: '-70px', ease: "ease" })
			.to(['#player', '#camera', '#audio'], 0.5, { y: '0px', ease: "ease" });

		gsap.timeline()
			.to('#pay', 0.5, { y: '70px', ease: "ease" })
			.to('#buy', 0.5, { y: '0px', ease: "ease" });

		mouseBlock.current = false;

	}

	const appHeight = () => {

  	const doc = document.documentElement;
  	doc.style.setProperty('--app-height', `${window.innerHeight}px`);

	}

	const init = () => {

		const width = window.innerWidth;
		const height = window.innerHeight;

		scene = new THREE.Scene();
		scene.background = new THREE.Color(0xFFFFFF);

		clock = new THREE.Clock();

		const ambientLight = new THREE.AmbientLight(0xFFFFFF, 0.25);
		scene.add(ambientLight);

		const spotLight = new THREE.SpotLight(0xFFFFFF, 0.5);
		spotLight.position.set(-10, 15, -50);
		scene.add(spotLight);

		const spotLight2 = new THREE.DirectionalLight(0xFFFFFF, 0.25);
		spotLight2.position.set(10, 25, -50);
		scene.add(spotLight2);

		const spotLight3 = new THREE.SpotLight(0xFFFFFF, 0.6);
		spotLight3.position.set(-10, 25, 75);
		scene.add(spotLight3);

		const spotLight4 = new THREE.SpotLight(0xFFFFFF, 0.1);
		spotLight4.position.set(-5, 15, -15);
		spotLight4.shadowMapWidth = 2560;
		spotLight4.shadowMapHeight = 2560;
		spotLight4.castShadow = true;
		spotLight4.shadow.camera.top = 2;
		spotLight4.shadow.camera.bottom = - 2;
		spotLight4.shadow.camera.left = - 2;
		spotLight4.shadow.camera.right = 2;
		spotLight4.shadow.camera.near = 0.1;
		spotLight4.shadow.camera.far = 50;
		scene.add(spotLight4);

		let loader = new GLTFLoader();
		loader;

		// GALLERY
		loader.setDRACOLoader(dracoLoader).load('/glb/gallery/Gallery.glb', async (gltf) => {

			gltf.scene.scale.set(0.05, 0.05, 0.05);
			gltf.scene.position.set(0, 0, 0);

			const geometry = new THREE.PlaneGeometry(2000, 2000);
			geometry.rotateX(-Math.PI / 2);

			const material = new THREE.ShadowMaterial();
			material.opacity = 0.2;

			const plane = new THREE.Mesh(geometry, material);
			plane.position.y = 5;
			plane.receiveShadow = true;
			gltf.scene.add(plane);

			scene.add(gltf.scene);

			setGallery(gltf.scene);

			const geometryPlane = new THREE.PlaneGeometry(7.5, 9.8);

			const materialPlane = new THREE.MeshBasicMaterial({ color: 0x000000, side: THREE.DoubleSide });
			materialPlane.opacity = 0;
			materialPlane.transparent = true;
			const plane2 = new THREE.Mesh(geometryPlane, materialPlane);
			plane2.position.set(-20, 5.1, 0.05);
			plane2.rotation.y = THREE.MathUtils.degToRad(90);

			setPlaneBlack(plane2);

			scene.add(plane2);

			loader.load('/glb/canvas/Canvas.glb', (gltf1) => {

				scene.background = new THREE.Color(0x87CEEB);

				gltf1.scene.scale.set(1, 1, 1);
				gltf1.scene.roughness = 0.5;

				const video = document.getElementById('video');

				const videoTexture = new THREE.VideoTexture(video);
				const videoMaterial =  new THREE.MeshStandardMaterial({
					map: videoTexture,
					side: THREE.DoubleSide,
					toneMapped: false
				});
				videoMaterial.roughness = 0.5;

				const screen = new THREE.PlaneGeometry(203, 216);
				const videoScreen = new THREE.Mesh(screen, videoMaterial);

				videoScreen.position.set(397.6, 177, -350);
				videoScreen.rotation.y = THREE.MathUtils.degToRad(90);

				gltf.scene.add(videoScreen);

				videoScreen.visible = false;

				setVideoScreen(videoScreen);

				gltf.scene.add(gltf1.scene);

				loader.setDRACOLoader(dracoLoader).load('/glb/land/Land.glb', (land) => {

					land.scene.scale.set(100, 100, 100);
					land.scene.rotation.y = - Math.PI / 3;
					land.scene.position.set(30, -27, 5);

					setLand(land.scene);

					scene.add(land.scene);

				});

			});

		});

		// ROBOT
		loader.setDRACOLoader(dracoLoader).load('/glb/robot/RobotDude.glb', async (gltf) => {

			gltf.scene.scale.set(4.1, 4.1, 4.1);
			gltf.scene.position.set(0, 0.2, 0);
			gltf.scene.rotation.y = THREE.MathUtils.degToRad(180);

			gltf.scene.traverse((object, i) => {

				object.frustumCulled = false;

				if (object.isMesh) {
					object.material.roughness = 0.5;
					object.material.metalness = 0;
					object.castShadow = true;
				}

			});

			scene.add(gltf.scene);

      mixer = new THREE.AnimationMixer(gltf.scene);

      gltf.animations.forEach((clip) => {

        mixer.clipAction(clip).play();

      });

			setPlayer0(gltf.scene);

		});

		// TREX
		loader.setDRACOLoader(dracoLoader).load('/glb/trex/TrexDude.glb', async (gltf) => {

			gltf.scene.scale.set(2.1, 2.1, 2.1);
			gltf.scene.position.set(-0.5, 0.2, 0);
			gltf.scene.rotation.y = THREE.MathUtils.degToRad(180);

			gltf.scene.traverse((object) => {

				if (object.isMesh) {
					object.material.roughness = 0.5;
					object.material.metalness = 0;
					object.castShadow = true;
				}

			});

			const spotLight = new THREE.DirectionalLight(0xFFFFFF, 0.6);
			spotLight.position.set(-2, 12, -5);
			gltf.scene.add(spotLight);

			gltf.scene.visible = false;

			scene.add(gltf.scene);

      mixer2 = new THREE.AnimationMixer(gltf.scene);

      gltf.animations.forEach((clip) => {

        mixer2.clipAction(clip).play();

      });

			setPlayer1(gltf.scene);

		});

		// WOMEN
		loader.setDRACOLoader(dracoLoader).load('/glb/women/WomenDude.glb', async (gltf) => {

			gltf.scene.scale.set(1.7, 1.7, 1.7);
			gltf.scene.position.set(-0.1, 4.6, 0.5);
			gltf.scene.rotation.y = THREE.MathUtils.degToRad(180);
			gltf.scene.doubleSided = false;

			gltf.scene.traverse((object) => {

				if (object.isMesh) {
					object.castShadow = true;
				}

			});

			const spotLight = new THREE.DirectionalLight(0xFFFFFF, 0.3);
			spotLight.position.set(-2, 12, -5);
			gltf.scene.add(spotLight);

			gltf.scene.visible = false;

			scene.add(gltf.scene);

			Nebula.fromJSONAsync(json, THREE).then(loaded => {

			  const nebulaRenderer = new SpriteRenderer(gltf.scene, THREE);
			  const nebula = loaded.addRenderer(nebulaRenderer);

			  animate(nebula, { scene: scene, camera: cameraControls, renderer: renderer });

			});

			setPlayer2(gltf.scene);

		});

		camera = new THREE.PerspectiveCamera(25, width / height, 1, 1000);
		camera.position.set(0, 25.37, 39.27);
    camera.rotation.set(-2.98, 0.01, 3.13);

    setCameraGlobal(camera);

		renderer = new THREE.WebGLRenderer({ antialias: true });
		renderer.shadowMap.enabled = true;
		renderer.shadowMapType = THREE.PCFSoftShadowMap;
		renderer.outputEncoding = THREE.sRGBEncoding;
		renderer.setPixelRatio(window.devicePixelRatio);
		renderer.setSize(window.innerWidth, window.innerHeight);
		document.getElementById('preview').appendChild(renderer.domElement);

		dragControls(renderer.domElement, dragAction);

		cameraControls = new OrbitControls(camera, renderer.domElement);

    cameraControls.screenSpacePanning = true;
		cameraControls.minDistance = 4;
		cameraControls.maxDistance = 39.8;
		// cameraControls.maxDistance = 99999;
		cameraControls.maxPolarAngle = Math.PI / 2;
    cameraControls.minPolarAngle = (90 - 10) * Math.PI / 180;
    cameraControls.enableDamping = true;
    cameraControls.dampingFactor = 0.05;

    cameraControls.target.set(0, 6, 0);

    cameraControls.update();

    setControlsGlobal(cameraControls);

    window.addEventListener('resize', onWindowResize);

	}

  const animate = (nebula) => {

	  requestAnimationFrame(() => animate(nebula, {
			scene: scene,
			camera: cameraControls,
			renderer: renderer
		}));

	  var delta = clock.getDelta();

	  if (mixer) mixer.update(delta);
		if (mixer2) mixer2.update(delta);

	  cameraControls.update();

		nebula.update();

	  renderer.render(scene, camera);

  }

	const onWindowResize = () => {

		camera.aspect = window.innerWidth / window.innerHeight;
		camera.updateProjectionMatrix();

		renderer.setSize(window.innerWidth, window.innerHeight);

	}

	const audioFadeIn = (audio, id) => {

    var myAudio = document.getElementById(audio);
		myAudio.src = "../audio/" + (id+1) + ".mp3";

		myAudio.currentTime = 0;
		myAudio.volume = 0.7;

		myAudio.play();


	}

	const audioFadeOut = (audio) => {

    var myAudio = document.getElementById(audio);

    if (myAudio.volume >= 0.1) {

        myAudio.volume -= .1;
        setTimeout(() => audioFadeOut(audio), 100);

    }

	}

	const toggleAudio = () => {

		var el = document.getElementById('audioEl');

		if (audio) { el.pause(); } else { el.play(); }

		setAudio(!audio);

	}

	const togglePlayer = () => {

		if (player < 2) { setPlayer(player+1); } else { setPlayer(0); }

	}

	const toggleCamera = () => {

		if (!cameraMove) {

			if (cameraType < 2) { setCameraType(cameraType+1); } else { setCameraType(0); }

		}

	}

	const dragControls = (canvas, dragAction) => {

    var mouseDown = false;

    canvas.addEventListener('mousemove', (evt) => {

      if (!mouseDown) { return }

      evt.preventDefault();

    }, false);

    canvas.addEventListener('mousedown', (evt) => {

			mouseTimeline.current.to('#cursorClick', 0.25, { width: 0, onComplete: () => setCursorClickText('CLICK') });

      evt.preventDefault();
      dragAction(true);

    }, false);

    canvas.addEventListener('mouseup', (evt) => {

        evt.preventDefault();
        dragAction(false);

    }, false);

	}

	const dragAction = (type) => {

		if (type) {

	    gsap.to(['#cursor'], 0.25, { scale: '0.8' });

		} else {

			gsap.to(['#cursor'], 0.25, { scale: '1' });

		}

	}

	const cursorClickEvent = (type, optional = true) => {

		if (type) {

			if (optional) {
				mouseTimeline.current.to('#cursorClick', 0.25, { width: 0, onComplete: () => setCursorClickText('CLICK') });
			}

			mouseTimeline.current
				.to('#cursorClick', 0.25, { width: 'auto' })
				.to('#cursorClick span', 0.25, { opacity: '1' });

			if (window.innerWidth > 768) {

				var el = document.getElementById('audioClick');

				el.play();

			}

		} else {

			mouseTimeline.current
				.to('#cursorClick span', 0.25, { opacity: 0 })
				.to('#cursorClick', 0.25, { width: 0 });

		}

	}

	const toggleSizes = (type) => {

		if (type === 1) {

			gsap.timeline()
				.set('#infosSecond', { display: 'block' })
				.to('#infosSecond', 0.25, { opacity: 1, pointerEvents: 'auto' });

			if (cameraType !== 1) { setCameraType(1) }

		} else {

			gsap.timeline()
				.to('#infosSecond', 0.25, { opacity: 0, pointerEvents: 'auto' })
				.set('#infosSecond', { display: 'none' });

			if (cameraType !== 0) { setCameraType(0) }

		}

	}

  return (
    <div className={ (initial ? styles.containerShow : styles.container) + (start ? " start" : "") }>

      <Head>
        <title>Shop (3D) — DUDE</title>
				<meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content="A nice T-shirt for dinosaurs, droids, statues, and even you." />
				<meta property="og:image" content="https://shop.dudemag.it/image/cover.jpg" />
				<link rel="apple-touch-icon" sizes="180x180" href="/favicon/apple-touch-icon.png" />
				<link rel="icon" type="image/png" sizes="32x32" href="/favicon/favicon-32x32.png" />
				<link rel="icon" type="image/png" sizes="16x16" href="/favicon/favicon-16x16.png" />
				<link rel="manifest" href="/favicon/site.webmanifest" />
				<link rel="mask-icon" href="/favicon/safari-pinned-tab.svg" color="#000000" />
				<meta name="msapplication-TileColor" content="#FFFFFF" />
				<meta name="theme-color" content="#FFFFFF" />
      </Head>

			<video
				id="video"
				playsInline muted loop
				width="320" height="240"
				style={{ opacity: 0, pointerEvents: 'none' }}
			></video>

			<div id="cursor">
				<div>

					<div id="cursorLoad"></div>

					<div id="cursorClick">
						<span>{ cursorClickText }</span>
					</div>

				</div>
			</div>

			<div id="loading" className={ styles.loading } onClick={ () => setStart(true) }>

				<Splash
					gsap={ gsap }
					initial={ initial }
					gallery={ gallery }
					land={ land }
					player0={ player0 }
					player1={ player1 }
					player2={ player2 }
					paymentDone={ paymentDone }
					closeCheckout={ closeCheckout }
					cursorClickEvent={ cursorClickEvent }
				/>

			</div>

			<div id="preview" className={ styles.preview }></div>

      <main className={ styles.main }>

				<div id="close" className={ styles.close }>

					<div
						className={ styles.closeButton }
						onMouseEnter={ () => cursorClickEvent(true) }
						onMouseLeave={ () => cursorClickEvent(false) }
					>

						<div className={ styles.closeButtonIcon } onClick={ () => closeCheckout() }></div>

					</div>

					<div
						className={ styles.checkoutButton }
						onMouseEnter={ () => cursorClickEvent(true) }
						onMouseLeave={ () => cursorClickEvent(false) }
					>

						<div
							className={ cartToggle ? styles.checkoutButtonGoSelected : styles.checkoutButtonGo }
							onClick={ () => setCartToggle(true) }
						>
							<span>Cart</span>
						</div>

						<div
							className={ cartToggle === false ? styles.checkoutButtonGoSelected : styles.checkoutButtonGo }
							onClick={ () => setCartToggle(false) }
						>
							<span>Checkout</span>
						</div>

					</div>

				</div>

				<div id="controller" className={ styles.controller }>

					<div
						style={{
							opacity: cameraType === 0 ? '1' : '0.28',
							pointerEvents: cameraType === 0 && !disableButtonPlayer ? 'auto' : 'none',
						}}
						id="player" className={ styles.players }
						onMouseEnter={ () => cursorClickEvent(true) }
						onMouseLeave={ () => cursorClickEvent(false) }
					>

						<div className={ styles.player } onClick={ () => togglePlayer() }>
							<div></div> <span>Character</span>
						</div>

					</div>

					<div className={ styles.cameraWrapper }>
						<div
							style={{
								opacity: checkout === 0 ? '1' : '0.28',
								pointerEvents: checkout === 0 && !disableButtonPlayer ? 'auto' : 'none',
							}}
							id="camera" className={ styles.camera }
							onClick={ () => toggleCamera() }
							onMouseEnter={ () => cursorClickEvent(true) }
							onMouseLeave={ () => cursorClickEvent(false) }
						>
							<div></div> <span>Camera</span>
						</div>
					</div>

					<div
						id="audio" className={ styles.audio }
						onMouseEnter={ () => cursorClickEvent(true) }
						onMouseLeave={ () => cursorClickEvent(false) }
					>

						<audio id="audioEl" loop />

						<div className={ audio ? styles.audioIconShow : styles.audioIcon } onClick={ () => toggleAudio() }></div>

					</div>

					<audio id="audioClick" />

				</div>

				<a aria-label="Go to shop.dudemag.it" target="_blank" rel="noreferrer" href="https://shop.dudemag.it">
					<div id="logo" className={ styles.logo }></div>
				</a>

				{ (stripe && clientSecret) && (
	        <Elements options={ options } stripe={ stripe }>
						<Cart
							gsap={ gsap }
							paymentIntent={ paymentIntent }
							setPaymentDone={ setPaymentDone }
							size={ size }
							setSize={ setSize }
							cameraType={ cameraType }
							setCheckout={ setCheckout }
							toggleSizes={ toggleSizes }
							mouseBlock={ mouseBlock }
							cursorClickEvent={ cursorClickEvent }
							locale={ router.locale }
						/>
	        </Elements>
				) }

      </main>

      <footer className={ styles.footer }>

				DUDE is a cultural innovation agency<br />
				that works on web design, social media,<br />
				video making and content creation.

				<div className={ styles.footerSocial }>

				<Link href="https://www.instagram.com/letmedude/">
							<a target="_blank" onMouseEnter={ () => cursorClickEvent(true) } onMouseLeave={ () => cursorClickEvent(false) }>
								<div className={ styles.footerSocialInstagram }></div>
							</a>
					</Link>

					<Link href="http://letmedude.it">
							<a target="_blank" onMouseEnter={ () => cursorClickEvent(true) } onMouseLeave={ () => cursorClickEvent(false) }>
								<div className={ styles.footerSocialWebsite }></div>
							</a>
					</Link>

					<Link href="mailto:info@hellodude.it">
						<a onMouseEnter={ () => cursorClickEvent(true) } onMouseLeave={ () => cursorClickEvent(false) }>
							<div className={ styles.footerSocialEmail }></div>
						</a>
					</Link>

				</div>

			</footer>

    </div>
	)
}
