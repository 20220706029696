import Link from 'next/link';
import styles from '../styles/Checkout.module.css';

import { PaymentElement } from '@stripe/react-stripe-js';

export default function Checkout({ gsap, register, handleSubmit, reset, watch, handle, message, errors, privacy, setPrivacy, terms, setTerms, privacyURL, termsURL, cursorClickEvent }) {

  return (
    <form
      encType="multipart/form-data"
      onSubmit={ handleSubmit(handle) }
      className={ styles.form }
      autocomplete="on"
    >

      <div className={ styles.input }>

        <label>Email</label>

        <input
          id="email"
          type="email"
          placeholder="Email address"
          style={{ backgroundColor: errors.email ? 'rgba(255, 0, 0, 0.28)' : 'rgba(0, 0, 0, 0.28)' }}
          { ...register("email", { required: true, pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: "invalid email address" } }) }
        />

        { (errors.email) && (
          <div className={ styles.error }>{ errors.email.message }</div>
        ) }

      </div>

      <div className={ styles.inputDouble }>

        <div style={{ paddingRight: 5 }} className={ styles.input }>

          <label>First name</label>

          <input
            id="firstName"
            type="text"
            placeholder="John"
            style={{ backgroundColor: errors.firstName ? 'rgba(255, 0, 0, 0.28)' : 'rgba(0, 0, 0, 0.28)' }}
            { ...register("firstName", { required: true }) }
          />

        </div>

        <div style={{ paddingLeft: 5 }} className={ styles.input } >

          <label>Last name</label>

          <input
            id="lastName"
            type="text"
            placeholder="Doe"
            style={{ backgroundColor: errors.lastName ? 'rgba(255, 0, 0, 0.28)' : 'rgba(0, 0, 0, 0.28)' }}
            { ...register("lastName", { required: true }) }
          />

        </div>

      </div>

      <div className={ styles.inputDouble }>

        <div style={{ paddingRight: 5 }} className={ styles.input }>

          <label>City</label>

          <input
            id="city"
            placeholder="New York"
            style={{ backgroundColor: errors.city ? 'rgba(255, 0, 0, 0.28)' : 'rgba(0, 0, 0, 0.28)' }}
            { ...register("city", { required: true }) }
          />

        </div>

        <div style={{ paddingLeft: 5 }} className={ styles.input }>

          <label>Postal code</label>

          <input
            id="postalCode"
            type="text"
            placeholder="11530"
            style={{ backgroundColor: errors.postalCode ? 'rgba(255, 0, 0, 0.28)' : 'rgba(0, 0, 0, 0.28)' }}
            { ...register("postalCode", { required: true }) }
          />

        </div>

      </div>

      <div className={ styles.input }>

        <label>Address line</label>

        <input
          id="addressLine"
          type="text"
          placeholder="123 Main St"
          style={{ backgroundColor: errors.addressLine ? 'rgba(255, 0, 0, 0.28)' : 'rgba(0, 0, 0, 0.28)' }}
          { ...register("addressLine", { required: true }) }
        />

      </div>

      <div className={ styles.input }>

        <label>Region</label>

        <input
          id="region"
          type="text"
          placeholder="New York"
          style={{ backgroundColor: errors.region ? 'rgba(255, 0, 0, 0.28)' : 'rgba(0, 0, 0, 0.28)' }}
          { ...register("region", { required: true }) }
        />

      </div>

      <PaymentElement id="paymentElement" />

      <div className={ styles.inputCheckbox }>

        <label>Privacy</label>

        <div className={ styles.checkbox }>

          <div
            className={ privacy ? styles.checkboxSquareSelected : styles.checkboxSquare }
            onMouseEnter={ () => cursorClickEvent(true) }
          	onMouseLeave={ () => cursorClickEvent(false) }
            onClick={ () => setPrivacy(!privacy) }
          ></div>

          <div className={ styles.checkboxLabel }>
            I accept the <Link href={ privacyURL ? privacyURL : "https://www.iubenda.com/privacy-policy/30419674" } passHref={ true }><a aria-label="Privacy policy" target="_blank" rel="noreferrer">privacy policy</a></Link>
          </div>

        </div>

      </div>

      <div className={ styles.inputCheckbox }>

        <label>Terms</label>

        <div className={ styles.checkbox }>

          <div
            className={ terms ? styles.checkboxSquareSelected : styles.checkboxSquare }
            onMouseEnter={ () => cursorClickEvent(true) }
          	onMouseLeave={ () => cursorClickEvent(false) }
            onClick={ () => setTerms(!terms) }
          ></div>

          <div className={ styles.checkboxLabel }>
            I accept the <Link href={ termsURL ? termsURL : "https://www.iubenda.com/termini-e-condizioni/30419674" } passHref={ true }><a aria-label="Terms and conditions" target="_blank">terms and conditions</a></Link>
          </div>

        </div>

      </div>

      <div className={ styles.inputMandatory }>
          All fields are required
      </div>

    </form>
  );
}
