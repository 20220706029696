import { useEffect, useState } from 'react';
import styles from '../styles/Cart.module.css';

import Checkout from './checkout.js';

import { useStripe, useElements } from '@stripe/react-stripe-js';
import { useForm } from "react-hook-form";

export default function Cart({ gsap, paymentIntent, setPaymentDone, size, setSize, cameraType, setCheckout, toggleSizes, mouseBlock, cursorClickEvent, locale }) {

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [privacy, setPrivacy] = useState(false);
  const [terms, setTerms] = useState(false);

  const privacyURL = {
    'it': "https://www.iubenda.com/privacy-policy/30419674",
    'en': "https://www.iubenda.com/privacy-policy/58472140",
  }

  const termsURL = {
    'it': "https://www.iubenda.com/termini-e-condizioni/30419674",
    'en': "https://www.iubenda.com/terms-and-conditions/58472140",
  }

  const { register, handleSubmit, reset, watch, formState: { errors } } = useForm({ mode: "onBlur" });

  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {

    if (!stripe) { return }

    const clientSecret = new URLSearchParams(window.location.search).get('payment_intent_client_secret');

    if (!clientSecret) { return }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {

      setPaymentDone(true);

    });

  }, [stripe]);

  const handle = async (data) => {

    if (!stripe || !elements) {
      return;
    }

    if (errors.message) { return; }

    setIsLoading(true);

    let body = data;

    const { error } = await stripe.confirmPayment({
      elements,
      redirect: "if_required",
      confirmParams: {
        return_url: window.location.href,
        receipt_email: data.email,
        shipping: {
          address: {
            line1: data.addressLine,
            city: data.city,
            postal_code: data.postalCode,
            state: data.region
          },
          name: data.firstName + " " + data.lastName + " " + size.toString(),
        }
      },
    });

    if (error) {

      setIsLoading(false);

      if (error.type === 'card_error' || error.type === 'validation_error') {

        setMessage(error.message);
        return;

      } else {

        setMessage('An unexpected error occured.');
        return;

      }

    }

    setPaymentDone(true);

    reset();
    setIsLoading(false);

  };

  const addTshirt = () => {

    gsap.timeline()
      .to('#infosSecond3', 0.5, { height: 0 })
      .set('#infosSecond3', { display: 'none', height: 0 })
      .set('#infosSecond1', { display: 'block' })
      .to('#infosSecond1', 0.5, { delay: 0.25, height: 'auto' });

  }

  const removeTshirt = (index) => {

    setSize(prev => [...prev.slice(0, index), ...prev.slice(index + 1)]);

  }

  return (
		<div
			id="infos"
			className={ styles.infos }
			onMouseEnter={ () => {
				if (cameraType === 0) {
					setCheckout(1);
          cursorClickEvent(false);
					mouseBlock.current = true;
					toggleSizes(1);
				}
			} }
			onMouseLeave={ () => {
				if (cameraType !== 2) {
					setCheckout(0);
					mouseBlock.current = false;
					toggleSizes(0);
				}
			} }
		>

      <div id="infosSecond" className={ styles.infosSecond }>

				<div id="infosSecond1" className={ styles.infosSecond1 }>

          <h3><span>Choose your size</span></h3> <br />

          <div className={ styles.infosSelect }>
            <div className="displayFlexWrap">

              <div
								className={ styles.infosSelectSquare }
								onClick={ () => setSize(prev => [...prev, 'S']) }
								onMouseEnter={ () => cursorClickEvent(true) }
								onMouseLeave={ () => cursorClickEvent(false)  }
							>
                <div>S</div>
              </div>
              <div
								className={ styles.infosSelectSquare }
								onClick={ () => setSize(prev => [...prev, 'M']) }
								onMouseEnter={ () => cursorClickEvent(true) }
								onMouseLeave={ () => cursorClickEvent(false) }
							>
                <div>M</div>
              </div>
              <div
								className={ styles.infosSelectSquare }
								onClick={ () => setSize(prev => [...prev, 'L']) }
								onMouseEnter={ () => cursorClickEvent(true) }
								onMouseLeave={ () => cursorClickEvent(false) }
							>
                <div>L</div>
              </div>
              <div
								className={ styles.infosSelectSquare }
								onClick={ () => setSize(prev => [...prev, 'XL']) }
								onMouseEnter={ () => cursorClickEvent(true) }
								onMouseLeave={ () => cursorClickEvent(false) }
							>
                <div>XL</div>
              </div>
              <div
								className={ styles.infosSelectSquare }
								onClick={ () => setSize(prev => [...prev, 'XXL']) }
								onMouseEnter={ () => cursorClickEvent(true) }
								onMouseLeave={ () => cursorClickEvent(false) }
							>
                <div>XXL</div>
              </div>

            </div>
          </div>

				</div>

				<div id="infosSecond2" className={ styles.infosSecond2 }>

          <Checkout
            gsap={ gsap }
            register={ register }
            handleSubmit={ handleSubmit }
            reset={ reset }
            watch={ watch }
            handle={ handle }
            message={ message }
            errors={ errors }
            privacy={ privacy }
            setPrivacy={ setPrivacy }
            terms={ terms }
            setTerms={ setTerms }
            privacyURL={ privacyURL[locale] }
            termsURL={ termsURL[locale] }
            cursorClickEvent={ cursorClickEvent }
          />

				</div>

        <div id="infosSecond3" className={ styles.infosSecond3 }>

          { size.map((item, i) => {
            return(
              <div className={ styles.infosSecondTable }>

                <div className={ styles.infosSecondRow }>
                  { (size.length > 1) && (
                     <div
                      className={ styles.infosSecondRowIconLabel }
                      onClick={ () => removeTshirt(i) }
          						onMouseEnter={ () => cursorClickEvent(true) }
          						onMouseLeave={ () => cursorClickEvent(false) }
                    ></div>
                   ) }
                   <span>Tshirt</span>
                </div>
                <div className={ styles.infosSecondRow }>
                  { (item === 'S') && 'Small' }
                  { (item === 'M') && 'Medium' }
                  { (item === 'L') && 'Large' }
                  { (item === 'XL') && 'X Large' }
                  { (item === 'XXL') && 'XX Large' }
                </div>

              </div>
            )
          }) }

          <div
            className={ styles.infosSecondTable }
            onClick={ () => addTshirt() }
						onMouseEnter={ () => cursorClickEvent(true) }
						onMouseLeave={ () => cursorClickEvent(false) }
          >

            <div className={ styles.infosSecondRow }>
              <div className={ styles.infosSecondRowIcon }></div> Add more
            </div>

          </div>


          <div className={ styles.infosSecondTable }>

            <div className={ styles.infosSecondRow }>Shipping</div>
            <div className={ styles.infosSecondRow }></div>
            <div className={ styles.infosSecondRow }>Free</div>

            <div className={ styles.infosSecondRow100 }>
              For country outside the EU <a href="mailto:info@hellodude.it">contact us</a>.
            </div>

          </div>

          <div className={ styles.infosSecondTable }>

            <div className={ styles.infosSecondRow }>Total</div>
            <div className={ styles.infosSecondRow }></div>
            <div className={ styles.infosSecondRow }></div>
            <div className={ styles.infosSecondRow }>{ 35 * size.length } €</div>

          </div>

        </div>

      </div>

      <div id="buy" className={ styles.buy }>
        <h3><span>35 €</span><span>Buy now</span></h3>
      </div>

  		<div
        style={{
          opacity: (errors.message || !privacy || !terms || isLoading) ? '0.68' : 1,
          pointerEvents: (errors.message || !privacy || !terms || isLoading) ? 'none' : 'auto'
        }}
        id="pay"
        onClick={ handleSubmit(handle) }
        className={ styles.pay }
        onMouseEnter={ () => cursorClickEvent(true) }
        onMouseLeave={ () => cursorClickEvent(false) }
      >
  			<h3><span>Free shipping</span><span>Pay { (35 * size.length) >= 35 ? 35 * size.length : 35 }€</span></h3>
  		</div>

    </div>
  );
}
